import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { receivedError, requestSent, responseRecived } from '../../../redux/actions/utilsActions';
import { addManualTimeSheetApi } from '../../../api/timeSheetApi';
import { convertDateFormat, tillNextWeekFriday } from '../../../utils/dateUtils';

const NoTimesheetRow = ({ data, isTImesheetApproved, getTimeSheetDetails, extraData }) => {
    const dispatch = useDispatch();
    const { userToken } = useSelector((state) => state.user);
    const [rowData, setRowData] = useState({ ...data, adjusted_amount: 0, remark: '' });
    
    const [hasTimesheetChanged, setIsTimesheetChanged] = useState(false);

    useEffect(() => {
        setRowData({ ...data, adjusted_amount: 0, remark: '' });
    }, [data]);

    const workUnit = data.WorkUnit;
    const dailyPayRate = data.Contractors[0].contractors_sole_traders.daily_pay_rate

    const hourlyRate = workUnit === 'HOURS' ?
        dailyPayRate || 0 : workUnit === 'DAYS' ?
            dailyPayRate / 9 : 0;

    const dayRate = workUnit === 'DAYS' ? dailyPayRate : 0;

    const onChnageUnfilledTimesheets = e => {
        let { value, name } = e.target;
        setRowData(p => {
            const dataToUpdate = {
                ...p,
                [name]: value
            };

            if (!dataToUpdate.remark && (!dataToUpdate.adjusted_amount || dataToUpdate.adjusted_amount === '0')) {
                setIsTimesheetChanged(false)
            } else {
                setIsTimesheetChanged(true)
            }

            return dataToUpdate;
        })
    };

    const saveTimesheetChanges = async () => {
        const workingPeriodStart = convertDateFormat(extraData.workPeriod.split(' - ')[0], 'DD/MM/YYYY', 'YYYY-MM-DD');
        const workingPeriodEnd = convertDateFormat(extraData.workPeriod.split(' - ')[1], 'DD/MM/YYYY', 'YYYY-MM-DD')

        try {
            dispatch(requestSent());
            await addManualTimeSheetApi(userToken, {
                working_period_start: workingPeriodStart,
                working_period_end: workingPeriodEnd,
                adjusted_amount: rowData.adjusted_amount,
                remark: rowData.remark,
                sole_trader_id: rowData.id,
                current_daily_pay_rate: dailyPayRate,
                last_date_of_pay_roll: tillNextWeekFriday(workingPeriodEnd, false)
            })
            dispatch(responseRecived());
            getTimeSheetDetails()
          } catch (error) {
            dispatch(receivedError(error));
            console.log("TimeSheet Api Error :::", error);
          }
    };

    return (
        <tr key={rowData.id}>
            <td style={{ minWidth: '10rem' }}>
                {
                    hasTimesheetChanged && <button className="approve_timesheet_btn" onClick={saveTimesheetChanges}>
                        SAVE
                    </button>
                }
            </td>
            <td>{rowData.SoleTraderProfile.firstname} {rowData.SoleTraderProfile.surname}</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td className="bordered">0.00</td>
            <td></td>
            <td>0.00</td>
            <td>{workUnit === 'AREA' ? '-' : hourlyRate.toFixed(2)}</td>
            <td>{workUnit === 'AREA' ? '-' : dayRate.toFixed(2)}</td>
            <td>£0.00</td>
            <td className="bordered">
                <input type='number' name="adjusted_amount" value={rowData.adjusted_amount} onChange={onChnageUnfilledTimesheets} disabled={isTImesheetApproved} />
            </td>
            <td className="bordered">
                <input type='text' name="remark" value={rowData.remark} onChange={onChnageUnfilledTimesheets} disabled={isTImesheetApproved} />
            </td>
            <td>£{rowData.adjusted_amount}</td>
        </tr>
    )
}

export default NoTimesheetRow
