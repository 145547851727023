import axios from "axios";

// Get All Time-Sheets //
export const getAllTimeSheet = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-all-timesheet?page=${params?.page}&limit=${params.limit}${params?.startDate ? (`&startDate=${params.startDate}`) : ''}${params?.endDate ? (`&endDate=${params.endDate}`) : ''}${params.site_id ? (`&site_id=${params.site_id}`) : ''}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })

            return resolve(response.data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}

// Get Site Time-Sheet Details //
export const getTimeSheetBySite = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-timesheet?page=${params?.page}&limit=${params.limit}${params.site_id ? (`&${params.site_id}`) : ''}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            resolve(response.data.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

// Get Time-Sheet Details For Working week //
export const getTimesheetDetailsApi = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-master-timesheet?wokring_period=${params.workPeriod}${params.selectedSites ? (`&selected_sites=${params.selectedSites}`) : ''}${params.selectedSoleTraders ? (`&selected_sole_traders=${params.selectedSoleTraders}`) : ''}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

export const downloadMeasuredWorksApi = async (userToken, params) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-measured-timesheet?working_period=${params.working_period}&sole_trader_id=${params.sole_trader_id}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                responseType: 'blob'
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}
export const downloadTimeSheetBySite = async (userToken, params) => {

    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/download-timesheet-pdf/${params}`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                responseType: 'blob'
            })

            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}
// Approve Time-Sheet //
export const approveSingleTimeSheetApi = async (userToken, changedData, isApproved) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/approve-single-timesheet?is_approved=${isApproved}`
        try {
            let response = await axios({
                method: 'post',
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data: {
                    data: JSON.stringify(changedData)
                }
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

export const addManualTimeSheetApi = async (userToken, data) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-manual-timesheet`
        try {
            let response = await axios({
                method: 'post',
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

// Approve manual Time-Sheet //
export const approveManualTimesheet = async (userToken, data) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/approve-manual-timesheet`
        try {
            let response = await axios({
                method: 'post',
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

export const approveMasterTimeSheetApi = async (userToken, working_period, changedData) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/approve-master-timesheet?working_period=${working_period}`
        try {
            let response = await axios({
                method: 'post',
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data: {
                    data: JSON.stringify(changedData)
                }
            })
            resolve(response.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}

// Get All Site TimeSheet Details //
export const getSiteTimeSheetDetailsApi = async (userToken) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/get-site-timesheet?detail=true`
        try {
            let response = await axios.get(url, {
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            })
            let customfields = { mondayTotal: 0, mondayTotaldays: 0, mondayTotalareas: 0, tuesdayTotal: 0, tuesdayTotalDays: 0, tuesdayTotalAreas: 0, wednesdayTotal: 0, wednesdayTotalDays: 0, wednesdayTotalAreas: 0, thursdayTotal: 0, thursdayTotalDays: 0, thursdayTotalAreas: 0, fridayTotal: 0, fridayTotalDays: 0, fridayTotalAreas: 0, saturdayTotal: 0, saturdayTotalDays: 0, saturdayTotalAreas: 0, sundayTotal: 0, sundayTotalDays: 0, sundayTotalAreas: 0, allTotalGross: 0, allTotalGrossDays: 0, allTotalGrossAreas: 0, allTotalDeduction: 0, allTotalHours: 0, allTotalDays: 0, allTotalAreas: 0 }
            let data = response.data.data.rows.map((site, siteIndex) => {
                const timeSheet = site.TimeSheets.map((contractor, timeSheetsIndex) => {
                    customfields['mondayTotal'] = customfields.mondayTotal + contractor.monday_working_hours
                    customfields['mondayTotaldays'] = customfields.mondayTotaldays + contractor.monday_working_days
                    customfields['mondayTotalareas'] = customfields.mondayTotalareas + contractor.monday_working_areas
                    customfields['tuesdayTotal'] += contractor.tuesday_working_hours
                    customfields['tuesdayTotalDays'] += contractor.tuesday_working_days
                    customfields['tuesdayTotalAreas'] += contractor.tuesday_working_areas
                    customfields['wednesdayTotal'] = customfields.wednesdayTotal + contractor.wednesday_working_hours
                    customfields['wednesdayTotalDays'] = customfields.wednesdayTotalDays + contractor.wednesday_working_days
                    customfields['wednesdayTotalAreas'] = customfields.wednesdayTotalAreas + contractor.wednesday_working_areas
                    customfields['thursdayTotal'] = customfields.thursdayTotal + contractor.thursday_working_hours
                    customfields['thursdayTotalDays'] = customfields.thursdayTotalDays + contractor.thursday_working_days
                    customfields['thursdayTotalAreas'] = customfields.thursdayTotalAreas + contractor.thursday_working_areas
                    customfields['fridayTotal'] = customfields.fridayTotal + contractor.friday_working_hours
                    customfields['fridayTotalDays'] = customfields.fridayTotalDays + contractor.friday_working_hours
                    customfields['fridayTotalAreas'] = customfields.fridayTotalAreas + contractor.friday_working_areas
                    customfields['saturdayTotal'] = customfields.saturdayTotal + contractor.staturday_working_hours
                    customfields['saturdayTotalDays'] = customfields.saturdayTotalDays + contractor.staturday_working_days
                    customfields['saturdayTotalAreas'] = customfields.saturdayTotalAreas + contractor.staturday_working_areas
                    customfields['sundayTotal'] = customfields.sundayTotal + contractor.sunday_working_hours
                    customfields['sundayTotalDays'] = customfields.sundayTotalDays + contractor.sunday_working_days
                    customfields['sundayTotalAreas'] = customfields.sundayTotalAreas + contractor.sunday_working_areas
                    customfields['allTotalGross'] = customfields.allTotalGross + Number(contractor.gross_amount)
                    customfields['allTotalGrossDays'] = customfields.allTotalGrossDays + Number(contractor.gross_amount_days)
                    customfields['allTotalGrossAreas'] = customfields.allTotalGrossAreas + Number(contractor.gross_amount_areas)
                    customfields['allTotalDeduction'] = customfields.allTotalDeduction + contractor.deduction
                    customfields['allTotalHours'] = customfields.allTotalHours + Number(contractor.total_hour_recorded)
                    customfields['allTotalDays'] = customfields.allTotalDays + Number(contractor.total_days_recorded)
                    customfields['allTotalAreas'] = customfields.allTotalAreas + Number(contractor.total_areas_recorded)
                    if (timeSheetsIndex == (site.TimeSheets.length - 1)) {
                        return ({ ...contractor, ...customfields })
                    }
                    return (contractor)
                })

                return ({ site: site.name, TimeSheets: timeSheet })
            })

            resolve(data)
        } catch (error) {
            console.log("api error::", error);
            reject(error)
        }
    })
}
// Deduction amount Time-Sheet //
export const addManualDeductionAmount = async (userToken, params, data) => {
    return new Promise(async (resolve, reject) => {
        const url = `${process.env.REACT_APP_ENVIROMENT === 'STAGING' ? process.env.REACT_APP_BASE_URL_STAGING : process.env.REACT_APP_BASE_URL_DEVELOPMENT}/contractor/add-manual-deductions/${params}`
        try {
            let response = await axios({
                method: "POST",
                headers: {
                    Authorization: userToken,
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                url: url,
                data: data
            })
            resolve(response.data.data)
        } catch (error) {
            console.log("api error::", error.response?.data);
            reject(error.response?.data)
        }
    })
}
